export async function ForgotPassword(email){
    const url = 'https://timlbackend.darien-c.com/password/reset/'
    try{
        const response = await fetch(url,{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body:JSON.stringify({
                "email": email
            }),
        })
        if(response.ok){
            const data = true
            return data
        }
        else{
            const data = false
            return data
        }
    }
    catch{
        alert("Error Sending Reset Password")
    }
}