export async function PostChatHistory(ChatHistory, partnerID){
    const token = localStorage.getItem('key')
    const url = 'https://timlbackend.darien-c.com/postChatHistory/'; // Update the URL as needed
    try{
        const response = await fetch(url, {
            method:"POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "token " + token
            },
            body:JSON.stringify({
                chat_history: ChatHistory,
                partnerID: partnerID,
                conversation_history: ChatHistory
                
            })
        })
        if (response.ok){
            const data = await response.JSON()
            return data
        }
        else{
        }
    }
    catch{
    }
}