import React from 'react'
import './NotLoggedIn.css'
import Button from 'react-bootstrap/Button';
import { useState, useEffect } from 'react';
import LoginModal from '../LoginModal';
const NotLoggedIn = () => {
    const [showLoginModalKey, setShowLoginModalKey] = useState(0)
    const [showLoginModal, setShowLoginModal] = useState(false)

    const handleShowLogin = () =>{
        setShowLoginModalKey(prevKey => prevKey + 1);
        setShowLoginModal(true)

    }
  return (
    <div>
        <div className="d-grid gap-2 chatButtonLogin">
      <Button variant="outline-light" size="lg" onClick={handleShowLogin}>
        Login To Use Chat
      </Button>
      </div>
      {showLoginModal && <LoginModal showModal={showLoginModalKey} isLoggedIn={false}/>}
    </div>
  )
}

export default NotLoggedIn