import React from 'react';

export async function Login(loginData) {
  const url = 'https://timlbackend.darien-c.com/login/'; // Update the URL as needed
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "username" : loginData.username, 
    "password": loginData.password }),
    });

    if (response.ok) {
      const data = await response.json();
      return data
    } else {
      console.error('Failed to submit journal:', response.statusText);
    }
  } catch (error) {
    console.error('Error while submitting journal:', error);
  }
}

export default Login;