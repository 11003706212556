import React from 'react'
import { useState, useEffect } from 'react';
import Transition from '../transition'
import './Chat.css';
import { CheckAuth } from '../services/CheckAuth';
import NotLoggedIn from '../components/ChatComponents/NotLoggedIn';
import ChooseTalker from '../components/ChatComponents/ChooseTalker';
const Chat = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    useEffect(()=>{
        CheckAuth()
        .then(data => {
            if(data !== false){
                setIsLoggedIn(true)

            }})
    },[])
    
    return (
        
        <div className="chatContainer">{isLoggedIn ? <ChooseTalker/>
        : 
        <NotLoggedIn/>}
            
        </div>
     
    )
}

export default Transition(Chat)
