export async function CheckAuth(){
    const url = 'https://timlbackend.darien-c.com/user/'; // Update the URL as needed
    const token = localStorage.getItem("key")
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'token ' + token
        }
,
      });
  
      if (response.ok) {
        const data = await response.json();
        
        return data
      } else {
        
        console.error('Failed to get user data:', response.statusText);
        const data= false
        return data
      }
    } catch (error) {
      console.error('Error while submitting user data:', error);
    }
  }