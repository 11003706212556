import AIChat from "../components/ChatComponents/AIChat";

export default function SelectTalker(id) {
    var elements = document.querySelectorAll(".avatarItem");
    const selectedElement = elements[id];
    const imgElement = selectedElement.querySelector('.avatarInfo');
    const parentElement = selectedElement.parentElement;
    const selectorText = document.querySelector('.selectorTitle')
    selectorText.className="avatarItem animate__animated animate__fadeOutDown"
    selectorText.remove()
    

    if (id >= 0 && id < elements.length) {

      
      
      elements.forEach((element) => {
        // Set the class name for all other elements
        element.className = "avatarItem animate__animated animate__fadeOutDown";
        setTimeout(() => {
            element.remove();          
        }, 1000);
       
      });
  
      // Restore the selected element after a delay
    //   setTimeout(() => {
    //     parentElement.appendChild(imgElement);
    //     imgElement.className="avatarItem animate__animated animate__fadeInDown"

    // }, 1000);
    } else {
    }
  }
  