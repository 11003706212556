import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import TIMLBox from './TIMLBox';
import OtherLinks from './OtherLinks';
import IntroModal from './IntroModal';

const HomeComponent = () => {
  const [showTIMLBox, setShowTIMLBox] = useState(false);
  
  const handleCloseModal = () => {
    setShowTIMLBox(true);
  };
  const checkIntro = ()=>{
    if(localStorage.getItem('checkIntro')){
      setShowTIMLBox(true)
    }
    else{
      localStorage.setItem('checkIntro',true)
    }
  }
  useEffect(()=>{
    checkIntro()

  },[])

  return (
    <div>
      {!showTIMLBox && <IntroModal handleClose={handleCloseModal} />}
      {showTIMLBox && <TIMLBox />}
      {/* <motion.div
          className="box"
          animate={{
            scale: [5, 1, 1, 1, 1],
            rotate: [0, 0, 270, 270, 0],
            borderRadius: ["20%", "20%", "50%", "50%", "20%"],
          }}
        /> */}
    </div>
  );
};

export default HomeComponent;
