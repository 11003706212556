import React from 'react'
import AnimatedTitle from './AnimatedTitle'
import { Link } from 'react-router-dom';
import './Title.css';
import LoginButton from './LoginButton';
import { useState } from 'react';
import LoginModal from './LoginModal';
import { CheckAuth } from '../services/CheckAuth'; 
const Title = () => {
    const titleText = 'this is my life'
    const journal = 'journal';
    const chat = 'chat'
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [showLoginModalKey, setShowLoginModalKey] = useState(0)
    const handleLoginModal = () => {
        CheckAuth().then(data => {
            const result = data;
            if (result) {
              setIsLoggedIn(true);
            }
          });
        
        // Generate a unique key  for the component, forcing it to re-render
        setShowLoginModalKey(prevKey => prevKey + 1);
    };
    return (
        <div className="headerMain">
        <div className="headerFloat">
           
            <div className="timlTitleDiv">
            <Link to="/" style={{textDecoration:"none"}}>
            <AnimatedTitle text={titleText} classType="main" smallHeader={false}/>
            </Link>
            </div>
            
        <LoginButton handleLogin= {handleLoginModal}/>    
       
        </div>
        
        <div className="links">
            <Link className="journalLink" to="/journal">
            <div className="journal">

                <AnimatedTitle text={journal} classType="journal" smallHeader={true}/>
                </div>
                </Link>
                
                <Link className="chatLink" to="/chat">
                <div className="chat">
                <AnimatedTitle text={chat} classType="chat" smallHeader={true}/>
                </div>
</Link>           
</div>
   <LoginModal showModal= {showLoginModalKey} loggedIn= {isLoggedIn}/>
   
        </div>
    )
}

export default Title
