import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './TIMLShareStory.css';
import SubmitTIML from '../../services/SubmitTIML';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
const TIMLShareStory = () => {
    const [showTIMLModal, setShowTIMLModal] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false);

    const [timlData, setTIMLData] = useState({
        story : '',
        name : '',
        email: ''
    })
    const handleTIMLChange = e =>{
       setTIMLData({ ...timlData,
        [e.target.name]: e.target.value
       

       })
    }
    const isEmailValid = (email) => {
        // Regular expression for basic email validation
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return emailRegex.test(email);
      };
    const handleTIMLSubmission = () => {
        if (timlData.story.length < 100) {
          toast.error("Minimum story length is 100 characters");
        } else if (!timlData.email || !isEmailValid(timlData.email)) {
          toast.error("Please input a valid email");
        } else {
          SubmitTIML(timlData)
            .then(data => {
              setShowSuccess(true);
              toast.success("Submission Was Successful!", {
                theme: "dark"
              });
              setTimeout(() => {
                document.location.reload();
              }, 2000);
            })
            .catch(error => {
              toast.error("Submission Failed Please Try Again", {
                theme: "dark"
              });
            });
        }
      };
    
    

  return (
    <div>
        <Button variant="outline-light" onClick={()=> setShowTIMLModal(true)}>
            Share Your Story
        </Button>

        <Modal show={showTIMLModal} fullscreen={true} onHide={() => setShowTIMLModal(false)} className="fullscreenmodal">
        <Modal.Header className="modalHeader">
       

    </Modal.Header>
        <Modal.Body>
        <button type="button" class="btn-close" aria-label="Close" onClick={(()=>setShowTIMLModal(false))}></button>
        {showSuccess ? (
            <div className="success-animation">Success!</div>
) : (
  <div>
    <div className="text-center intro">
      <p>
        TIML is a space where you can share moments in your life. Whether it is a story of tragedy, triumph, or one that is still resolving, we strive to create a community that will provide compassion while simultaneously fostering growth. You can choose to submit it anonymously or not. There will be a new story every day. Include your email for a notification on your story being selected.
        <br />
        <b>The only criteria we use to select these stories is honesty. Thank you for sharing.</b>
      </p>
    </div>
    <div className="inputItems">
      <input value={timlData.name} onChange={handleTIMLChange} name="name" type="text" className="nameInput text-center" placeholder="Name (Optional)" />
      <br />
      <input value={timlData.email} onChange={handleTIMLChange} name="email" type="text" className="emailInput text-center" placeholder="Email" required/>
      <br />
      <textarea value={timlData.story} onChange={handleTIMLChange} name="story" type="text" className="textInput" rows="30" placeholder="Share Your Story" />
      <Button variant="outline-light" onClick={handleTIMLSubmission}>
        Submit Story
      </Button>
    </div>
  </div>
)}

            
        </Modal.Body>

      </Modal>

    </div>
  )
}

export default TIMLShareStory