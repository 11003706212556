import { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import './AnimatedTIML.css'
export default function AnimatedTIML({text, classType}) {

  const ctrls = useAnimation();

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      ctrls.start("visible");
    }
    if (!inView) {
      ctrls.start("hidden");
    }
  }, [ctrls, inView]);

  const wordAnimation = {
    hidden: {},
    visible: {},
  };

  const characterAnimation = {
    hidden: {
      opacity: 0,
      y: `0.25em`,
    },
    visible: {
      opacity: 1,
      y: `0em`,
      transition: {
        duration: 1,
        ease: [0.25, 0.1, 0.25, 1],
      },
    },
  };
  return (
    <div className={classType}>
      <h3 aria-label={text} role="heading" className={classType}>
        {text.split(" ").map((word, index) => {
          return (
            <motion.span
              ref={ref}
              aria-hidden="true"
              key={index}
              className={classType}
              initial="hidden"
              animate={ctrls}
              variants={wordAnimation}
              transition={{
                delayChildren: index * 0.22,
                staggerChildren: 0.05,
              }}
            >
              
                
                  <motion.span
                    aria-hidden="true"
                    key={index}
                    className={classType}
                    variants={characterAnimation}
                  >
                    {word + ' '}
                  </motion.span>
                
              
            </motion.span>
          );
        })}
      </h3>
    </div>
  );
}
