export async function UserData(token){
        const url = 'https://timlbackend.darien-c.com/user/'; // Update the URL as needed
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'token ' + token
            }
,
          });
      
          if (response.ok) {
            const data = await response.json();
            return data
          } else {
            console.error('Failed to get user data:', response.statusText);
          }
        } catch (error) {
          console.error('Error while submitting user data:', error);
        }
      }

export default UserData;