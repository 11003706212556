import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './ChatModal.css'
import { useEffect, useState } from 'react';

const ChatModal = ({ chatData, modalKey }) => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (modalKey !== 0) {
            setShowModal(true);
        }
    }, [modalKey]);

    return (
        <div>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header style={{color:"black"}}>
                    <b>
                    Conversation History
                    </b>
                </Modal.Header>
                <Modal.Body>         
                <div className="chatbox-container">
      <div className="chat-history"> 
                    {chatData.map((message, index) => (
          <div key={index} className={`message ${message.sender}`}>
            {message.content}
          </div>
        ))}
        </div>
        </div>
          
                    
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ChatModal;
