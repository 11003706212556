import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import './ShowJournalItem.css';
const ShowJournalItem = ({ item, show }) => {
    const [showModal, setShowModal] = useState(false)
    const handleClose = () =>{
        setShowModal(false)
    }
    useEffect(()=>{
        if (show !== 0) {

            setShowModal(true);
        }
    }, [show]);
  return (
    <div>
      <Modal show={showModal} onHide={handleClose} className="journalItem "       aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header id="contained-modal-title-vcenter">
          {item.date}
        </Modal.Header>
        <Modal.Body>
          <p>
            {item.journalData}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <hr />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ShowJournalItem;
