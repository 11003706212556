import React, { useEffect, useState } from 'react';
import AnimatedTitle from './AnimatedTitle';
import Title from './Title';
import OtherLinks from './OtherLinks';
import LoginButton from './LoginButton';
import {Link} from 'react-router-dom'

const Header = () => {
  const [text, setText] = useState('TIML'); // Initial text
  const [showFullText, setShowFullText] = useState(false);

  useEffect(() => {
    // Wait for a certain time before revealing the full text
    const timer = setTimeout(() => {
      setShowFullText(true);
    }, 2000); // Adjust the duration as needed (2 seconds in this example)

    return () => {
      clearTimeout(timer); // Clear the timer if the component unmounts
    };
  }, []);

  return (
    <div>
     
      <Title/>
       
     


    </div>
  );
};

export default Header;
