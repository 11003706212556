import React from 'react'
import './ChooseTalker.css'
import { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import SelectTalker from '../../services/SelectTalker';
import AIChat from './AIChat';
import { GetChatHistory } from '../../services/GetChatHistory';
import ChatModal from './ChatModal';

const ChooseTalker = () => {
    const [selectedAvatarIndex, setSelectedAvatarIndex] = useState(null);
    const [showAIChat, setShowAIChat] = useState(false);
    const [showChatMenu, setShowChatMenu] = useState(false)
    const [chatLogData, setChatLogData] = useState([])
    const [activeChatLog, setActiveChatLog] = useState([])
    const [showModalKey, setShowModalKey] = useState(0)
    const popOutRef = useRef(null);

    const handleShowTalker = (id) =>{
        SelectTalker(id)
        setTimeout(()=>{
            const container = document.querySelector(".avatarContainer")
            container.remove()
            
            setShowAIChat(true)
        }, 1500)
    }

    const handleClickOutside = (event) => {
        if (popOutRef.current && !popOutRef.current.contains(event.target)) {
          setShowChatMenu(false);
        }
      }
    const showChatLogItem = (chatLog) =>{
        
        setActiveChatLog(chatLog.conversation_history)
        setShowModalKey(prev=> prev + 1)
    }
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
    
    
    useEffect(()=>{
        GetChatHistory()
        .then(data=>{
            setChatLogData(data)
        })
      },[])




  return (
    <div className="mainChatContainer">
    <div className="animate__animated animate__fadeInDown">
        <div className="text-center selectorTitle"><h3>Who Would You Like To Chat With?</h3>
        <div className="showChatDiv">
        <a onClick={(()=>setShowChatMenu(true))}>
           
        <img src="/menuButton.png" alt="Journal Button" className="journalMenuContainer"/>
        </a>
     
        </div>
        </div>
       
        

        <div className="avatarContainer">
            <div className="avatarItem">
                <div className="avatarInfo">
        <img className="avatarImage" onClick={(()=> setSelectedAvatarIndex(0))} src='https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortFlat&accessoriesType=Blank&hairColor=BrownDark&facialHairType=Blank&clotheType=CollarSweater&clotheColor=Blue&eyeType=Default&eyebrowType=RaisedExcitedNatural&mouthType=Smile&skinColor=Brown'
width="200px" height="200px"/>
<div >
<p className="text-center">Mike</p>
</div>
</div>
{selectedAvatarIndex === 0 && (
    <div className="animate__animated animate__fadeInDown text-center personDescription">
      <p>Warm</p>
      <p>Curious</p>
      <p>Compassionate</p>
      <Button variant="primary" onClick={(()=> handleShowTalker(0))}>Start Chat!</Button>    </div>
  )}


</div>
<div className="avatarItem">
<div className="avatarInfo">

<img className="avatarImage" onClick={(()=> setSelectedAvatarIndex(1))} src='https://avataaars.io/?avatarStyle=Transparent&topType=LongHairStraight&accessoriesType=Prescription02&hairColor=BrownDark&facialHairType=Blank&clotheType=CollarSweater&clotheColor=PastelYellow&eyeType=Happy&eyebrowType=Default&mouthType=Smile&skinColor=Pale'
width="200px" height="200px"/>
<p className="text-center">Lisa</p>
</div>

{selectedAvatarIndex === 1 && (
    <div className="animate__animated animate__fadeInDown text-center personDescription">
<p>Honest</p>
<p>Sharp</p>
<p>Logical</p>
<Button variant="primary" onClick={(()=> handleShowTalker(1))}>Start Chat!</Button></div>)}
</div>
<div className="avatarItem">
<div className="avatarInfo">

<img className="avatarImage" onClick={(()=> setSelectedAvatarIndex(2))} src='https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairTheCaesarSidePart&accessoriesType=Blank&hairColor=Black&facialHairType=BeardLight&facialHairColor=Black&clotheType=Hoodie&clotheColor=Gray01&eyeType=Happy&eyebrowType=Default&mouthType=Smile&skinColor=Black'
width="200px" height="200px"/>
<p className="text-center">Doug</p>
</div>
{ selectedAvatarIndex === 2 && (
<div className="animate__animated animate__fadeInDown text-center ">
    <div className="personDescription">
<p>Confident</p>
<p>Witty</p>
<p>Charismatic</p>
</div>
<Button variant="primary" onClick={(()=> handleShowTalker(2))}>Start Chat!</Button>
</div>
)}

</div>
<div className="avatarItem">
<div className="avatarInfo">

<img className="avatarImage" onClick={(()=> setSelectedAvatarIndex(3))} src='https://avataaars.io/?avatarStyle=Transparent&topType=LongHairBob&accessoriesType=Blank&hairColor=BlondeGolden&facialHairType=Blank&clotheType=CollarSweater&clotheColor=Heather&eyeType=Default&eyebrowType=FlatNatural&mouthType=Smile&skinColor=Pale'
width="200px" height="200px"/>
<p className="text-center">Cindy</p>
</div>
{selectedAvatarIndex ===3 && (
    <div className="animate__animated animate__fadeInDown text-center personDescription">
        <p>Creative</p>
<p>Care-Free</p>
<p>Playful</p>
<Button variant="primary" onClick={(()=> handleShowTalker(3))}>Start Chat!</Button> </div>
)}

    </div></div>
    </div>
    <div className='popOut' style={{ left: showChatMenu ? '0' : '-100%', zIndex: showChatMenu ? '2' : '0' }} ref={popOutRef}>
              <ul className="navbar-menu">
                <li><b>Chat History</b></li>
  
    {chatLogData.map((item, index) => (

      <li key={index} className="navbar-menu__item">
        <a onClick={(()=>{showChatLogItem(item)})}>
            {item.date}
            </a>
      </li>
    ))}
</ul>
                </div>
                <div
                    className="overlay"
                    style={{ opacity: showChatMenu ? '1' : '0', zIndex:showChatMenu ? 1: '-1'}}
                  ></div>
    <div>
    {showAIChat && <AIChat id={selectedAvatarIndex}/>}
    <ChatModal chatData={activeChatLog} modalKey={showModalKey}/>

    </div>

    </div>
  )
}

export default ChooseTalker