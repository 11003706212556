import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginSuccessful = () => {
  const { key } = useParams();
  const navigate = useNavigate()

  useEffect(() => {
    if (key) {
      // Send a POST request to your server to confirm the email
      const confirmationData = { key }; // Key is directly obtained from the URL parameter

      fetch('https://timlbackend.darien-c.com/register/verify-email/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(confirmationData),
      })
        .then((response) => {
          if (response.status === 200) {
            // Email confirmation successful
            toast.success("Email Confirmed Successfully");
            setTimeout(()=>{
                navigate('/')

                

            },500)
          } else {
            // Email confirmation failed
            toast.error("Email Confirmation Failed");
            setTimeout(()=>{
                navigate('')

                

            },500)
          }
        })
        .catch((error) => {
          console.error('Error confirming email:', error);
          toast.error("Error confirming email");
        });
    }
  }, [key]); // Include 'key' in the dependency array to re-run the effect when it changes

  return (
    <div className="login-successful">
      {/* You can display a loading spinner or a message while the confirmation is in progress */}
      <p>Confirming your email...</p>
    </div>
  );
};

export default LoginSuccessful;
