import React from 'react'
import { useEffect } from 'react'
import './AIChat.css'
import AIChatBox from './AIChatBox'

const AIChat = ({id}) => {
    const imageSources = [{
        '0': 'https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortFlat&accessoriesType=Blank&hairColor=BrownDark&facialHairType=Blank&clotheType=CollarSweater&clotheColor=Blue&eyeType=Default&eyebrowType=RaisedExcitedNatural&mouthType=Smile&skinColor=Brown',
        "1" : 'https://avataaars.io/?avatarStyle=Transparent&topType=LongHairStraight&accessoriesType=Prescription02&hairColor=BrownDark&facialHairType=Blank&clotheType=CollarSweater&clotheColor=PastelYellow&eyeType=Happy&eyebrowType=Default&mouthType=Smile&skinColor=Pale',
        "2" : "https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairTheCaesarSidePart&accessoriesType=Blank&hairColor=Black&facialHairType=BeardLight&facialHairColor=Black&clotheType=Hoodie&clotheColor=Gray01&eyeType=Happy&eyebrowType=Default&mouthType=Smile&skinColor=Black",
        "3" : 'https://avataaars.io/?avatarStyle=Transparent&topType=LongHairBob&accessoriesType=Blank&hairColor=BlondeGolden&facialHairType=Blank&clotheType=CollarSweater&clotheColor=Heather&eyeType=Default&eyebrowType=FlatNatural&mouthType=Smile&skinColor=Pale'

    }]



    useEffect(() => {
        
        // Add the 'beforeunload' event listener
        window.addEventListener('beforeunload', confirmBeforeUnload);
    
        // Remove the event listener when the component unmounts
        return () => {
          window.removeEventListener('beforeunload', confirmBeforeUnload);
        };
      }, []);
    
      // Function to show a confirmation dialog when the user tries to leave the page
      const confirmBeforeUnload = (e) => {
        // Check if you need to show the confirmation message
        
          e.preventDefault();
          e.returnValue = ''; // This is what will be displayed in the confirmation dialog
        
      };
  return (
    <div>
        
        <div className="animate__animated animate__fadeInDown mainChatBoxContainer">

        <img src={imageSources[0][id]} alt="selectedImage"/>
        <AIChatBox id= {id}/>
        </div>
    </div>
  )
}

export default AIChat