export async function SubmitTIML(TIMLData) {
    const url = 'https://timlbackend.darien-c.com/postTIML/';

    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({  // JSON.stringify() the object
                "name": TIMLData.name,
                "email": TIMLData.email,
                "timl_data": TIMLData.story,
            }),
        });

        if (response.ok) {
            const data = await response.json(); // Use response.json() to parse JSON data
            return data;
        } else {
            throw new Error("Posting TIMLData went wrong"); // Throw an error to handle it in the calling code
        }
    } catch (error) {
        console.error("Submission Went Wrong", error);
        throw error; // Rethrow the error to handle it in the calling code
    }
}
export default SubmitTIML;