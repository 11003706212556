import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Login from '../services/Login';
import UserData from '../services/UserData';
import './LoginModal.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from 'react';
import Logout from '../services/Logout';
import { Register } from '../services/Register';
import { ForgotPassword } from '../services/ForgotPassword';
const LoginModal = ({showModal, loggedIn}) => {
    const [ show, setShow] = useState(false)
    const [loginData, setLoginData] = useState({
        username: '',
        password: ''
    })
    const [registerData, setRegisterData] = useState({
        username: "",
        email: "",
        password1: "",
        password2:""
    })
    const [showRegister, setShowRegister] = useState(false)
    const [resetEmail, setResetEmail] = useState('')
    const [showForgotPassword, setShowForgotPassword] = useState(false)
    const handleResetEmail = e=>{
      setResetEmail(e.target.value)
    }
    const handleResetSubmit = () => {
      if (!isValidEmail(resetEmail)) {
        // Validate the email address here (you can define the isValidEmail function)
        toast.error("Invalid email address", { theme: "dark" });
        return;
      }
    
      ForgotPassword(resetEmail)
        .then(data => {
          toast.success("Password Reset Link Sent To " + resetEmail, { theme: "dark" });
        })
        .catch(error => {
          toast.error("An error occurred while sending the reset link" + error, { theme: "dark" });
        });
    };
    
    function isValidEmail(email) {
      // Implement email validation logic (e.g., using a regular expression)
      return /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(email);
    }

    const handleLoginFormChange = e =>{
        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value
        });

    }
    const handleRegisterFormChange = e =>{
        setRegisterData({
            ...registerData,
            [e.target.name]: e.target.value

        })
    }
    const handleRegisterSubmit = () => {
      const { password1, email } = registerData;
    
      // Define password complexity criteria
      const passwordCriteria = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    
      // Check if password meets the criteria
      if (!passwordCriteria.test(password1)) {
        toast.error("Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character.", {
          theme: "dark"
        });
        return;
      }
    
      // Proceed with the registration if password is complex enough
      Register({ registerData })
        .then(data => {
          if (data === true) {
            setShow(false);
            toast.success("Confirmation Email Sent To " + email, {
              theme: "dark"
            });
    
            setTimeout(() => {
              document.location.reload();
            }, 1500);
          } else {
            console.log(data);
            toast.error("Registration Failed", {
              theme: "dark"
            });
          }
        })
        .catch(error => {
          console.error(error);
          toast.error("An error occurred during registration", {
            theme: "dark"
          });
        });
    };
    
    

    const handleClose = () =>{
        setShow(!show)
    }



    const handleLogin = () => {

      Login(loginData)
        .then((data) => {
          localStorage.setItem('key', data.key);

          UserData(data.key)
            .then((userData) => {
              localStorage.setItem('userName', userData.username);
              setShow(false)
             
             
                toast.success(userData.username + " Logged In", {
                    theme: "dark"
                });
               
            
            // Reload the page after the success message is displayed
            setTimeout(function () {
                document.location.reload();
            }, 1000); // Adjust the delay as needed

                        // Run the toast success after setting localStorage and on a timeout to allow for page refresh
              
            })
            .catch((error) => {
              console.error('Error fetching user data:', error);
            
              // Handle the error here, e.g., display an error message or take other actions
              toast.error('Error fetching user data', { theme: 'dark' });
            });
        })
        .catch((error) => {
          console.error('Login error:', error);
    
          // Handle the error here, e.g., display an error message or take other actions
          toast.error('Login failed', { theme: 'dark' });
        });
    };
    const handleLogout = () => {
        Logout().then(() => {
            setShow(false)

            toast.success("Logged Out", {
                theme: "dark"
            });
    
            // Reload the page after the success message is displayed
            setTimeout(() => {
                document.location.reload();
            }, 1000); // Adjust the delay as needed
        });
    };

    
    useEffect(()=>{
        if (showModal !== 0) {
            setShow(true);
        }
    }, [showModal]);
    

  return (
<div>
  {loggedIn ? (
    <Modal show={show} onHide={handleClose} className="loginModal">
    <Modal.Header className="modal-header">
      <Modal.Title className="modal-title">You're Logged In</Modal.Title>
    </Modal.Header>
    <Modal.Body className="modal-body">
      <p>You are already logged in. No further action is required.</p>
      <br />
      <Button variant="light" onClick={handleLogout} >
        Logout
      </Button>
    </Modal.Body>
    <Modal.Footer>
      {/* Footer content, if any */}
    </Modal.Footer>
  </Modal>
  ) : (
    <Modal show={show} onHide={handleClose} className="loginModal">
    {showForgotPassword ? (
      <div>
        <Modal.Header className="modal-header">
      <Modal.Title className="modal-title">Reset Password</Modal.Title>
    </Modal.Header>
    <Modal.Body className="modal-body">
    <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Enter Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Email"
                name="email"
                value={resetEmail}
                onChange={handleResetEmail}
                className="form-control"
              />
            </Form.Group>
      <br />
      <Button variant="outline-dark" onClick={(()=>handleResetSubmit())}>Reset Password</Button>

    </Modal.Body>
    <Modal.Footer>
    <Button variant="outline-dark" onClick={(()=>setShowForgotPassword(false))} >
        Login
      </Button>    </Modal.Footer>

      </div>
    ):(
    showRegister ? (
      <div>
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title">Register</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="username"
                placeholder="Enter Username"
                name="username"
                value={registerData.username}
                onChange={handleRegisterFormChange}
                className="form-control"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Email"
                name="email"
                value={registerData.email}
                onChange={handleRegisterFormChange}
                className="form-control"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter Password"
                name="password1"
                value={registerData.password1}
                onChange={handleRegisterFormChange}
                className="form-control"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                name="password2"
                value={registerData.password2}
                onChange={handleRegisterFormChange}
                className="form-control"
              />
            </Form.Group>
            <Button variant="light" onClick={handleRegisterSubmit}>Submit Registration</Button>
          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={()=>setShowForgotPassword(true)}>Forgot Password?</Button>
          <Button variant="light" onClick={() => setShowRegister(false)} className="login-button">
            Login
          </Button>
        </Modal.Footer>
            </div>
            
        ) : (
            <div>
                 <Modal.Header className="modal-header">
          <Modal.Title className="modal-title">Login</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="username"
                placeholder="Enter username"
                name="username"
                value={loginData.username}
                onChange={handleLoginFormChange}
                className="form-control"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter password"
                name="password"
                value={loginData.password}
                onChange={handleLoginFormChange}
                className="form-control"
              />
            </Form.Group>
            <Button variant="light" onClick={handleLogin} className="submit-button">
              Submit
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="light" onClick={()=>setShowForgotPassword(true)}>Forgot Password?</Button>

          <Button variant="light" onClick={() => setShowRegister(true)} className="register-button">
            Register Account
          </Button>
        </Modal.Footer>
      </div>
    )
    )}
   
  </Modal>


  )}
</div>

  )
}

export default LoginModal