import { useAnimate } from 'framer-motion';
import React, { useState } from 'react';
import './PasswordReset.css'
import { useNavigate, useParams } from 'react-router-dom';
import {toast} from 'react-toastify'
import { Button } from 'react-bootstrap';
function PasswordReset() {
  const { uid, token } = useParams();
  const navigate = useNavigate()
  
  const [passwordData, setPasswordData] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({
      ...passwordData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Password validation regex
    const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
  
    // Check if password meets criteria
    if (!passwordRegex.test(passwordData.newPassword)) {
      // Password does not meet criteria
      toast.error("Password must contain at least 8 characters, including one uppercase letter, one number, and one special character (!@#$%^&*)");
      return;
    }
  
    const resetData = {
      uid,
      token,
      new_password1: passwordData.newPassword,
      new_password2: passwordData.confirmPassword,
    };
  
    fetch('https://timlbackend.darien-c.com/password/reset/confirm/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(resetData),
    })
    .then((response) => {
      if (response.status === 200) {
        // Password reset successful
        // You can redirect the user to a success page or perform other actions.
        toast.success("Password Successfully Reset!")
        setTimeout(()=>{
          navigate('')
        }, 1500)
      } else {
        // Handle password reset failure, e.g., show an error message.
        console.error('Password reset failed');
        toast.error("Password Reset Failed", response.error)
      }
    })
    .catch((error) => {
      console.error('Error resetting password:', error);
      toast.error(error)
    });
  };

  return (
    <div className="password-reset-container">
      <h2>Password Reset</h2>
      <form onSubmit={handleSubmit} className="password-reset-form">
        <div>
          <label htmlFor="newPassword">New Password</label>
          <input
            type="password"
            name="newPassword"
            value={passwordData.newPassword}
            onChange={handlePasswordChange}
          />
        </div>
        <div>
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            name="confirmPassword"
            value={passwordData.confirmPassword}
            onChange={handlePasswordChange}
          />
        </div>
        <Button variant="outline-light" onClick={handleSubmit}>Reset Password</Button>
      </form>
    </div>
  );
}

export default PasswordReset;
