export async function Register({ registerData }) {
    // Define the URLs
    const registrationVerificationURL = "https://timlbackend.darien-c.com/registerValid/";
    const registrationURL = "https://timlbackend.darien-c.com/register/";

    try {
        // Verify registration data first
        const verificationResponse = await fetch(registrationVerificationURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username: registerData.username,
                email: registerData.email
            })
        });

        if (verificationResponse.ok) {
            // If verification is successful, proceed with registration
            const registrationResponse = await fetch(registrationURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    username: registerData.username,
                    email: registerData.email,
                    password1: registerData.password1,
                    password2: registerData.password2
                })
            });

            // Check if registration was successful
            if (registrationResponse.ok) {
                // Registration successful
                return true;
            } else {
                // Registration failed
                const errorMessage = await registrationResponse.text();
                throw new Error(`Registration failed: ${errorMessage}`);
            }
        } else {
            // Verification failed
            console.log(verificationResponse)
            
        }
    } catch (error) {
        // Handle any errors
        console.error("An error occurred during registration:", error);
        throw error;
    }
}
