import React, { useState,useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import './JournalLog.css';
import Button from 'react-bootstrap/Button';
import SubmitJournal from '../services/SubmitJournal';
import { toast } from 'react-toastify';

const JournalLog = () => {
    const [inputText, setInputText] = useState('');

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setInputText(inputValue);
    };
    const handleJournalSubmit = ()=>{
        const token = localStorage.getItem("key")
        if(token){
            SubmitJournal(inputText, token)
            toast.success("journal saved", {
                theme: "dark"
              })
              setTimeout(()=>{
                window.location.reload()
              }, 1500)
        }else{
            toast.error("Please Login To Save Journal", {
                theme:"dark"
            })
        }

    }
        // Use the useEffect hook to focus on the textarea when the component mounts
        useEffect(() => {
            const textarea = document.querySelector('.journalText');
            if (textarea) {
                textarea.focus();
            }
        }, []);

    return (
        <div>
            <div className="journalTextContainer">
                <textarea
                    className="journalText"
                    placeholder="what's on your mind"
                    rows={20}
                    value={inputText}
                    onChange={handleInputChange}
                />
                            <a onClick={handleJournalSubmit}>
            <div className="text-right">
  <Button variant="outline-light" className="saveButton">Submit</Button>
</div>            </a>     
            </div>
   
        </div>
    );
};

export default JournalLog;
