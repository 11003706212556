import React, { useState, useEffect, useRef } from 'react';
import './AIChatBox.css';
import { AIChatDialogue } from '../../services/AIChatDialogue';
import { PostChatHistory } from '../../services/PostChatHistory';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/esm/Button';

const AIChatBox = ({ id }) => {
  const [chatHistory, setChatHistory] = useState([]);
  const [userMessage, setUserMessage] = useState('');

  const [isLoading, setIsLoading] = useState(true); // Added loading state
  const [conversationHistory, setConversationHistory] = useState([])
  const [loadingMessage, setLoadingMessage] = useState("Thinking...")

  const chatHistoryRef = useRef(); // Create a ref for chat history container

  const scrollToBottom = () => {
    chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
  };

  const handleUserMessageChange = (e) => {
    setUserMessage(e.target.value);
  };

  const handleSendMessage = () => {
    const newUserMessage = { sender: 'user', content: userMessage };
    const userBackend = { role: 'user', content: userMessage };
    setChatHistory([...chatHistory, newUserMessage]);
    setConversationHistory([...conversationHistory, userBackend])
    setUserMessage('');
    setIsLoading(true);

    AIChatDialogue({ message: newUserMessage.content, id, initialMessage: false, conversationHistory: conversationHistory })
      .then((data) => {
        if(data.Count === true){
            toast.error("Daily Message Limit Exceeded", {theme:"dark"})
            setLoadingMessage("Daily Message Limit Exceeded")
            setIsLoading(true)
        }
        else if(data) {
          const aiResponse = {
            sender: 'ai',
            content: data.response,
            

          };
          const aiBackend = {
            role: 'system',
            content: data.response,
            

          };
          setChatHistory((prevChatHistory) => [...prevChatHistory, aiResponse]);
          setConversationHistory((prevConversationHistory)=> [...prevConversationHistory, aiBackend])

        } else {
          console.error('Invalid AI response structure');
        }
      })
      .catch((error) => {
        console.error('Error sending user message or receiving AI response:', error);
      })
      .finally(() => {
        setIsLoading(false);
        scrollToBottom(); // Scroll to the latest message after the AI response
      });
  };
  const handleSaveChatHistory= () =>{
    PostChatHistory(chatHistory, id)
    .then(data=>{
       toast.success("Chat Saved", {
           theme: "dark"
       });     })
 }



    useEffect(() => {
    AIChatDialogue({
      message: 'Hi, can you say hello and start a conversation with me, and can you say your name in the first message.',
      id,
      initialMessage: true,
      conversationHistory: conversationHistory
    })
      .then((data) => {
        console.log(data)
        if(data.Count === true){
            toast.error("Daily Message Limit Exceeded", {theme:"dark"})
            setLoadingMessage("Daily Message Limit Exceeded")
            setIsLoading(true)
        }else{
        const { choices } = data;

        const firstMessage = {
          sender: 'ai',
          content: data.response,
        };
        const firstBackend = {
            role: 'system',
            content: data.response,
          };

        setChatHistory((prevChatHistory) => [...prevChatHistory, firstMessage]);
        setConversationHistory((prevConversationHistory)=> [...prevConversationHistory, firstBackend])
        setIsLoading(false)
  }})
      .catch((error) => {
        console.error('Error fetching initial AI message:', error);
      });
  }, [id]);

  useEffect(() => {
    scrollToBottom(); // Scroll to the latest message after the initial message is received

  }, [chatHistory]);


  return (
    <div className="chatbox-container">
      <div className="chat-history" ref={chatHistoryRef}>
        {chatHistory.map((message, index) => (
          <div key={index} className={`message ${message.sender}`}>
            {message.content}
          </div>
        ))}
        {isLoading && <div className="loading-indicator">{loadingMessage}</div>}
      </div>
      <div className="input-container">
        <input
          type="text"
          value={userMessage}
          onChange={handleUserMessageChange}
          placeholder="Type a message..."
          rows="1"  // Set the initial number of rows to control the initial height
          style={{ overflowY: "auto" }} // Enable vertical scrolling if needed
        />
        <button className="send-button" onClick={handleSendMessage} disabled={isLoading}>
          Send
        </button>
      </div>
      <br/>
      <div><Button onClick={handleSaveChatHistory} variant="outline-light">Save Chat</Button></div>
    </div>
  );
};

export default AIChatBox;
