import React from 'react'
import HomeComponent from '../components/HomeComponent'
import Header from '../components/Header'
import Transition from '../transition'
const Home = () => {
  return (
    <div>
        <HomeComponent/>
    </div>
  )
}

export default Transition(Home)