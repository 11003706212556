export async function GetChatHistory() {
    const token = localStorage.getItem("key");
    const url = "https://timlbackend.darien-c.com/getChatHistory/";
  
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "token " + token,
        },
      });
  
      if (response.ok) {
        const data = await response.json(); // Use response.json() here
        return data;
      } else {
        console.log("Something went wrong with the response");
      }
    } catch (error) {
      console.log("Something went wrong with the POST request");
    }
  }
  