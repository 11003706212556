import React from 'react'
import './LoginButton.css';
const LoginButton = ({ handleLogin }) => {
  return (
    <div>
        <a onClick={handleLogin}>
        <img src="/loginIcon.png" alt="login button" className="loginButton"/>
        </a>
    </div>
  )
}

export default LoginButton