import React from 'react'
import { useState,useEffect } from 'react'
import { getTIMLText } from '../services/getTIMLText'
import AnimatedTitle from './AnimatedTitle'
import './TIMLBox.css';
import 'animate.css';


import AnimatedTIML from './AnimatedTIML';
import TIMLShareStory from './TIMLShareStory/TIMLShareStory';
const TIMLBox = () => {
    const [timlPostText, setTIMLPostText] = useState(null)
    const [loading, setLoading] = useState(false)
    const [showTIML, setShowTIML] = useState(false)
    const [animationComplete, setAnimationComplete] = useState(false)
    const [continueReading, setContinueReading] = useState('Read More')
    const [showFullTIML, setShowFullTIML] = useState(false)
    const [timlPostFullText, setTimlPostFullText] = useState('')
    const [showTIMLPostText, setShowTIMLPostText] = useState(false)
    const [showTIMLShareButton, setShowTIMLShareButton] = useState(false)
    const waitAndShowTIML = ()=>{
        setTimeout(()=>{
            setShowTIML(true);
        }, 2000);
        setTimeout(()=>{
            setShowTIMLShareButton(true);
        }, 5000);
    }
    useEffect(() => {
        const fetchData = async () => {
          try {
            const idNumber = 12;
            const data = await getTIMLText(idNumber);
            setTimlPostFullText(data.timlData);
            const firstTenCharacters = data.timlData.slice(0, 40);
            const postFinal = firstTenCharacters + ' ...';
            setTIMLPostText(postFinal);
            waitAndShowTIML();
            handleAnimationComplete();
          } catch (error) {
            setLoading(true);

            setTimeout(()=>{
                // If there's an error, set TIMLPostText to "Loading Please Wait" and retry
                setShowTIML(true);
            }, 20000);

            setTimeout((
                fetchData() // Retry the request

            ), 30500)
          }
        };
    
        fetchData();
      }, []);
     const handleAnimationComplete = ()=>{
        setTimeout(()=>{
            setAnimationComplete(true);
        }, 4000);
     }
     const handleShowTIML = ()=>{
        setShowFullTIML(true)
        setTimeout(()=>{
            setShowTIMLPostText(true)

        }, 1000)
     }
  return (
<div className="TIMLContainer">
  {loading ? (
    <div>
      <h3>Loading...</h3>
    </div>
  ) : (
    <>
      {showTIML && (
        <div className={`TIMLText ${showFullTIML ? 'animate__animated animate__fadeOutDown' : ''}`}>
          <AnimatedTIML text={timlPostText} classType="text" onAnimationComplete={animationComplete} />
          {animationComplete && (
            <div className="readMoreContainer">
              <a onClick={handleShowTIML}>
                <small>
                  <u>
                    <AnimatedTIML text={continueReading} classType="readMore" />
                  </u>
                </small>
              </a>
            </div>
          )}
          <br />
          {showTIMLShareButton && (
            <div className={` ${showFullTIML ? 'animate__animated animate__fadeOutDown' : 'animate__animated animate__fadeInDown'}`}>
              <TIMLShareStory />
            </div>
          )}
        </div>
      )}

      {showTIMLPostText && (
        <div className={`TIMLText ${showTIMLPostText ? 'animate__animated animate__fadeInDown' : ''}`}>
          <p>{timlPostFullText}</p>
          <div className='animate__animated animate__fadeInDown'>
            <TIMLShareStory />
          </div>
        </div>
      )}
    </>
  )}
</div>

  )
}

export default TIMLBox
