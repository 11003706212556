export async function AIChatDialogue({ message, id, initalMessage, conversationHistory}) {
    const url = "https://timlbackend.darien-c.com/getResponse/";
    const token = localStorage.getItem('key');
    let roleID = id;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/JSON",
          "Authorization": "token " + token, // Fix this line
        },
        body: JSON.stringify({
          "message": message,
          "id": roleID,
          "conversation_history": conversationHistory,
          "initalMessage": initalMessage
        }),
      });
  
      if (response.ok) {
        console.log(response)
        
        const data = await response.json(); // Fix this line
        console.log(data)
        return data
      } else {
      }
    } catch (error) {
      console.log("error occured")
    }
  }
  