import './App.css';
import Home from './pages/Home';
import Chat from './pages/Chat';
import Journal from './pages/Journal';
import { Routes, Route, useLocation} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Header from './components/Header';
import Transition from './transition';

import PasswordReset from './pages/PasswordReset';
import LoginSuccessful from './pages/LoginSuccessful';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

  

function App() {
  const location = useLocation()

  return (
<>
<Header />
<ToastContainer />


        <AnimatePresence mode='wait'>
          <Routes location={location} key={location.pathname}>
            <Route index element={<Home />} />
            <Route path="chat" element={<Chat/>}/>
             
            <Route path="journal" element={<Journal />} />
            <Route path="email/confirm/:key" element={<LoginSuccessful />} />
            <Route path="password-reset/confirm/:uid/:token" element={<PasswordReset/>}/>
          </Routes>
        </AnimatePresence>
    </>
  );
}

export default App;
