import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './IntroModal.css'; // Import the CSS file

const IntroModal = ({ handleClose }) => {
  
  return (
    <>
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Welcome to TIML!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your Personal Space for Mental Well-being</p>
          <p>TIML is your trusted companion in the journey towards better mental health. Our app offers a safe and supportive environment where you can:</p>
          <ul>
            <li>
              <p><strong>📔 Journal:</strong> Reflect on your thoughts and emotions in your personal digital journal.</p>
            </li>
            <li>
              <p><strong>📖 Share Stories:</strong> Connect with others by sharing your experiences and reading stories from the community.</p>
            </li>
            <li>
              <p><strong>🤖 Chat with AI Helper:</strong> Get instant support and guidance from our AI-powered assistant, always ready to listen and help.</p>
            </li>
          </ul>
          <p>Take a step towards a healthier mind. Let's begin this journey together.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Get Started
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default IntroModal;
