async function getTIMLText(dataToSend) {
    try {
      const response = await fetch('https://timlbackend.darien-c.com/getTIML/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Set the appropriate content type
        },
        body: JSON.stringify({'id' : dataToSend}), // Convert your data to JSON format
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error posting data:', error);
      throw error; // Re-throw the error for error handling in the calling component
    }
  }
  
  // Export the asynchronous function
  export { getTIMLText };