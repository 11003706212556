export async function Logout(){
    const token = localStorage.getItem('key')
    const url = 'https://timlbackend.darien-c.com/logout/'; // Update the URL as needed

    const response = await fetch(url, {
        method:"POST", 
        header: {
            "Content-Type": "application/json",
            "Authorization": "token " + token
        }
    })
    if(response.ok){
        const data= await response.json()
        localStorage.removeItem("key")
        localStorage.removeItem("userName")
    }
    else{
    }

}

export default Logout;