import React, { useState, useEffect, useRef } from 'react';
import Transition from '../transition';
import JournalLog from '../components/JournalLog';
import JournalLogMenu from '../components/JournalLogMenu';
import './Journal.css';
import MenuButton from '../components/MenuButton';
import ShowJournalItem from '../components/ShowJournalItem';
import GetJournal from '../services/GetJournal';
const Journal = () => {
  const [showJournalMenu, setShowJournalMenu] = useState(false);
  const popOutRef = useRef(null);
  const [journalLogs, setJournalLogs] = useState([])
  const [activeJournalData, setActiveJournalData] = useState({
    date: '',
    journalData: ''
  });
  const [showActiveJournal, setShowActiveJournal] = useState(0)
  const handleJournalMenu = () => {
    setShowJournalMenu(true);
  };
  const closeJournalMenu = () => {
    setShowJournalMenu(false);
  };
  const showJournalLog = (item) =>{
    setActiveJournalData(item)
    setShowActiveJournal(prev=> prev + 1)

  }
  useEffect(()=>{
  },[showJournalMenu])
  const handleClickOutside = (event) => {
    if (popOutRef.current && !popOutRef.current.contains(event.target)) {
      setShowJournalMenu(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleJournals = () =>{
    GetJournal()
    .then((data)=>{
        setJournalLogs(data)
        
    })
  }


  useEffect(()=>{
     handleJournals()
  },[])




  return (
    <div className="journalContainer">
      
       
       <div className="animate__animated animate__fadeInDown">
       <a onClick={handleJournalMenu}>
        <img src="/menuButton.png" alt="Menu Button" className="menuButtonContainer"/>
        </a>
</div>
          
    
    
      
        <div>
              <div className='popOut' style={{ left: showJournalMenu ? '0' : '-100%', zIndex: showJournalMenu ? '2' : '0' }} ref={popOutRef}>
              <ul className="navbar-menu">
  {journalLogs ? (
    journalLogs.map((item, index) => (
      <li key={index} className="navbar-menu__item">
        <a onClick={() => showJournalLog(item)}>{item.date}</a>
      </li>
    ))
  ) : (
    <li className="navbar-menu__item">Please Log In To Save Entries</li>
  )}
</ul>
                </div>
               
                  <div
                    className="overlay"
                    style={{ opacity: showJournalMenu ? '1' : '0', zIndex:showJournalMenu ? 1: '-1'}}
                  ></div>
                  </div>
    
     
      <JournalLog />
          <ShowJournalItem show={showActiveJournal} item ={activeJournalData}/>
        
  
   

    </div>
  );
};


  export default Transition(Journal)


