import { motion } from 'framer-motion';
import { useEffect } from 'react';

const Transition = (OgComponent) => {
  return () => (
    <>
      <OgComponent />
      <motion.div
        className="slide-in-motion"
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 0 }}
        exit={{ scaleX: 1 }}
        transition={{ duration: 0.5, ease: [0.22, 1, 0.36, 1] }}
      />
<motion.div
  className="slide-out-motion"
  initial={{ scaleX: 1 }}
  animate={{ scaleX: 0 }}
  exit={{ scaleX: 0 }}
  transition={{ duration: 0.5, ease: [0.22, 1, 0.36, 1], delay: 0.3 }}
/>
    </>
  );
};


// Conditionally export Transition only if isPageRefresh is false
export default Transition;
