import React from 'react';

export async function GetJournal() {
  const url = 'https://timlbackend.darien-c.com/getJournal/'; // Update the URL as needed
  const token = localStorage.getItem("key")
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization" : "token " + token
      }
    //   },
    //   body : JSON.Stringify({
    //     "token": token

    //   })
    });

    if (response.ok) {
      const data = await response.json();
      return data
    } else {
      console.error('Failed to submit journal:', response.statusText);
    }
  } catch (error) {
    console.error('Error while submitting journal:', error);
  }
}

export default GetJournal;
