import React from 'react';

export async function SubmitJournal(text, token) {
  const url = 'https://timlbackend.darien-c.com/postJournal/'; // Update the URL as needed

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "token " + token
      },
      body: JSON.stringify({ 'journalData' : text, 
    'journalPrompt': 'samplePrompt' }),
    });

    if (response.ok) {
      const data = await response.json();
    } else {
      console.error('Failed to log in user:', response.statusText);
    }
  } catch (error) {
    console.error('Error trying to login:', error);
  }
}

export default SubmitJournal;
